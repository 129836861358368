<template>
  <div class="box-body">
    <div class="panel panel-default">
      <div class="form-group row mt-3 mx-1">
        <div class="col-md-3">
          <div class="bg-gray small-box">
            <div class="inner">
              <h5 class="text-white">Создан</h5>
              <h3 class="text-white">{{ carContract.created_date }}</h3>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div :class="(carContract.is_expired ? 'bg-danger' : 'bg-success') + ' small-box'">
            <div class="inner">
              <h5 class="text-white">Оплачен до</h5>
              <h3 class="text-white">{{ carContract.paid_date }}</h3>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="bg-info small-box">
            <div class="inner" style="margin-bottom: 20px;">
              <h5 class="text-white">Страница 1</h5>
            </div>
            <div class="icon">
              <i class="fas fa-file-alt"></i>
            </div>
            <a :href="apiURL + '/car/' + carId + '/contract/page/1'"
               target="_blank"
               class="small-box-footer">
              Открыть
              <i class="fas fa-arrow-circle-right"></i>
            </a>
          </div>
        </div>
        <div class="col-md-3">
          <div class="bg-info small-box">
            <div class="inner" style="margin-bottom: 20px;">
              <h5 class="text-white">Страница 2</h5>
            </div>
            <div class="icon">
              <i class="fas fa-file-alt"></i>
            </div>
            <a :href="apiURL + '/car/' + carId + '/contract/page/2'"
               target="_blank"
               class="small-box-footer">
              Открыть
              <i class="fas fa-arrow-circle-right"></i>
            </a>
          </div>
        </div>
      </div>

      <hr />

      <div class="form-group row mt-3 mx-1">
        <h4>Оплаченные счета</h4>

        <div v-if="this.car_contract_files.length" class="table-responsive">
          <table class="table table-striped table-valign-middle">
            <thead>
            <tr>
              <th>Файл квитанции</th>
              <th>Дата добавления</th>
              <th>Продление до</th>
              <th>Добавил</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="contract_file in car_contract_files">
              <td>
                <a :href="contract_file.storage_app_url + contract_file.file_url" target="_blank">
                  {{ contract_file.file_url}}
                </a>
              </td>
              <td>
                {{ contract_file.created_date }}
              </td>
              <td>
                {{ contract_file.paid_to_date }}
              </td>
              <td>
                {{ contract_file.creator.name }} (ID: {{ contract_file.creator.id}})
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div v-else class="text-center text-muted mt-3 mb-3">
          Нет элементов для отображения
        </div>
      </div>

      <hr />

      <div
        v-if="([1, 4, 7, 8, 9].includes($store.getters['auth/authUser'].role.id) || carResponsibleId === $store.getters['auth/authUser'].id)"
        class="form-group col-12">
        <transition name="fade">
          <vue-dropzone v-if="onProgressLoadFile === false"
                        id="dropzone-files"
                        ref="car_contract_files"
                        :duplicateCheck="true"
                        :options="dropzoneOptionsFiles"
                        v-on:vdropzone-file-added="sendFile"
                        v-on:vdropzone-success="getFiles"
                        v-bind:style="onProgressLoadFile === true ? 'opacity: 0.5;' : 'opacity: 1;'">
          </vue-dropzone>
          <loading-processing v-else :size="60"></loading-processing>
        </transition>
      </div>

      <transition name="fade">
        <div class="row" v-if="onProgress === false">
          <div class="col-lg-6 col-md-12" v-for="item in car_contract_files">
            <div class="image-box">
              <img
                v-if="dropzoneOptionsFiles.img_extensions.includes(item.file_url.slice(item.file_url.lastIndexOf('.')))"
                :src="item.storage_app_url + item.file_url"
                class="img-fluid p-3" :alt="item.description">
              <div v-else class="full-height p-3">
                <div class="p-2 bg-gray-light">
                  <h5 class="mb-3 font-scale-2" style="word-break: break-all;">
                    {{ item.file_url.slice(item.file_url.lastIndexOf("/")).substring(1) }}

                    <br />

                    <small class="font-small-xs">
                      {{ item.created_date }}
                    </small>

                    <br />
                    <br />
                  </h5>
                </div>
              </div>
              <button @click="removeFile(item.id)"
                      v-if="!isDeleted"
                      class="btn" title="Удалить файл" type="button">
                <i class="fa fa-trash"></i>
              </button>
              <a :href="item.storage_app_url + item.file_url"
                 target="_blank" class="btn" title="Открыть"
                 style="left: 82%; right: 0; opacity: 0.85;">
                <i class="fas fa-external-link-square-alt"></i>
              </a>
            </div>
          </div>
          <div v-if="car_contract_files.length === 0" class="text-center text-muted mt-5 mb-5">
            Нет элементов для отображения
          </div>
        </div>
        <loading-processing v-else></loading-processing>
      </transition>
    </div>
  </div>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import dropzone_config from "./../../../assets/data/dropzone-config.json";

export default {
  name: "car-contract",

  components: {
    vueDropzone: vue2Dropzone,
  },

  props: {
    carId: {
      type: [String, Number],
      required: true,
    },
    carResponsibleId: {
      type: [String, Number],
      required: true,
    },
    carContract: {
      type: [Object, Array],
      required: true,
    },
  },

  data: () => ({
    car_contract_files: [],

    dropzoneOptionsFiles: dropzone_config,

    onProgress: true,
    onProgressLoadFile: false,

    apiURL: process.env.VUE_APP_API_URL,
  }),

  async created() {
    try {
      this.dropzoneOptionsFiles.url = "/car/" + this.carId + "/contract/files/load";

      await this.getFiles();
    } catch (error) {
      errorHandler(error);
    }
  },

  methods: {
    async getFiles() {
      try {
        const car_contract_files_response = await API.apiClient.get("/car/" + this.carId + "/contract/files");

        this.car_contract_files = car_contract_files_response.data;
      } catch (error) {
        errorHandler(error);
      }

      this.onProgress = false;
    },

    async sendFile(file) {
      try {
        this.onProgressLoadFile = true;
        this.$refs.car_contract_files.disable();

        const form_data = new FormData();
        form_data.append("car_id", this.carId.toString());
        form_data.append("file", file);

        await API.apiClient.post(this.dropzoneOptionsFiles.url, form_data).then(() => {
          this.onProgressLoadFile = false;
          this.getFiles();
          showSuccess();
        });

        this.$refs.car_contract_files.removeFile(file);
      } catch (error) {
        this.onProgressLoadFile = false;
        errorHandler(error);
      }

      this.$refs.car_contract_files.enable();
    },

    removeFile(file_id) {
      this.onProgress = true;

      API.apiClient.delete("/car/" + this.carId + "/contract/files", {
        data: {
          file_id: file_id,
        },
      }).then(() => {
        this.getFiles();
        showSuccess();
      }).catch((error) => {
        errorHandler(error);
      });
    },
  },
};
</script>

<style scoped>
.image-box {
  position: relative;
}

.image-box img {
  width: 100%;
  height: auto;
}

.image-box .btn {
  position: absolute;
  bottom: 5%;
  right: 70%;
  transform: translate(-45%, -55%);
  -ms-transform: translate(-45%, -55%);
  background-color: #424656;
  color: white;
  font-size: 14px;
  border: none;
  cursor: pointer;
  border-radius: 2px;
  opacity: 0.65;
  max-width: 45px;
}

.image-box .btn:hover {
  background-color: black;
}
</style>
